import React from 'react'
import "./ContactDiv.css"
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationArrow } from "react-icons/fa";

export default function ContactDiv() {
    return (
        <div className='contact-div-main'>
            <div className='contact-text-divs' >
                <div>
                    <FaPhone className='svg-contact' />
                    <h1 className='contact-heading'>Phone</h1>
                    <p>+1 (713) 925-5623</p>
                </div>
            </div>

            <div className='contact-text-divs'>
                <div>
                    <MdEmail className='svg-contact' />
                    <h1 className='contact-heading'>Email</h1>
                    <p>store@leatherscrafts.com</p>
                </div>
            </div>

            <div className='contact-text-divs'>
                <div>
                    <FaLocationArrow className='svg-contact' />
                    <h1 className='contact-heading'>Address</h1>
                    <p>Pakistan, Sindh, Karachi, L1274, sector 4B, Surjani Town, Karachi Central
                        POBOX number 07510
                    </p>
                </div>
            </div>
        </div>
    )
}
