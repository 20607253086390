import axios from 'axios'

const GET_METHOD = async (link) => {
    try {
        const res = await axios.get(link);
        return res.data;
    } catch (error) {
        console.log("Error fetching ", error.message);
    }
}

const POST_METHOD = async (link, body) => {
    console.log(link , body);
    try {
        const res = await axios.post(link, body, {
            headers: {
                'Content-Type': 'multipart/form-data' 
            }
        });
        if (res.status >= 200 && res.status < 300) {
            return {
                success: true,
                data: res.data
            };
        } else {
            return {
                success: false,
                message: `Unexpected response code: ${res.status}`
            };
        }
    } catch (error) {
        console.log("Error posting:", error.message);
        return {
            success: false,
            message: error.message
        };
    }
};


export { GET_METHOD, POST_METHOD }