import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosCheckmarkCircle } from "react-icons/io";
import "./OrderSummary.css";
import Button from "../../Component/Button/Button";
import Footer from "../../Component/Footer/Footer";
import Navbar from "../../Component/Navigation/Navbar/Navbar";
import { GET_METHOD } from "../../api/api";
import { useDispatch } from "react-redux";
import { cartRemoveAllProducts } from "../../stores/slice";
import OrderStatus from "../../Component/CheckoutComponents/OrderStatus/OrderStatus";
import { IoBanOutline } from "react-icons/io5";

export default function OrderSummary() {
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const { id } = useParams();
  const cartId = id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cartRemoveAllProducts());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await GET_METHOD(
        `https://knitting.azurewebsites.net/api/ECommerceApi/GetInvoiceDetail?CartId=${cartId}&CampusId=50`
      );
      setProductData(res);
    };
    fetchData();
  }, [cartId]);

  const handleObject = (desc) => {
    if (typeof desc === 'object' && Object.keys(desc).length === 0) {
      return '';
    } else if (typeof desc === 'object') {
      return JSON.stringify(desc);
    } else {
      return desc;
    }
  };

  let totalAmountWithoutDiscount = 0;
  let discount = 0;
  let shippingCharges = 0;

  const handlePayment = async () => {
    const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/Checkout?CartId=${cartId}&CampusId=50`);
    console.log(res, 'res of cart-id api');
    window.open(res, '_blank');
  }

  return (
    <>
      <Navbar />
      {productData?.length > 0 ? (
        <div className="main-order-summary">
          <h1 className="order-summary-heading">
            Thank you for your purchase!
          </h1>
          <div className="sub-main-order-summary">
            <div className="order-summary-thankyou-main-div">
              <div className="order-summary-confirmed-div">
                <h2>Order confirmed successfully</h2>
                <IoIosCheckmarkCircle size={50} color="green" />
                <p className="order-summary-order-id">
                  <b>Order Number: </b> {handleObject(productData[0]?.OrderNo)}
                </p>
                <div>
                  <OrderStatus currentStatus={handleObject(productData[0]?.CartStatus)} />
                </div>
              </div>

              <div className="order-summary-order-details">
                <h2>Order Details</h2>
                <div className="sub-order-summary-order-details">
                  <div className="order-summary-order-details-div">
                    <h3>Shipping Address</h3>
                    <p>{handleObject(productData[0]?.CustomerName)}</p>
                    <p>{handleObject(productData[0]?.DeliveryAddress)}</p>
                    <p>{handleObject(productData[0]?.CountryName)}</p>
                  </div>

                  <div>
                    <h3>Contact Information</h3>
                    <p>{handleObject(productData[0]?.CustomerEmail)}</p>
                    <p>{handleObject(productData[0]?.CustomerNo)}</p>
                  </div>
                </div>
              </div>

              <div className="order-summary-btn">
                <Button
                  text="Continue Shopping"
                  onClick={() => navigate("/shop")}
                  style={{ padding: '0rem', lineHeight: 0, height: '3rem', width: '250px' }}
                />
              </div>
            </div>

            <div className="order-summary-products-main-div">
              <div className="order-summary-sub-checkout-products">
                {productData.map((item, index) => {
                  const itemDiscount = parseFloat(item.TotalDiscountAmount) || 0;
                  const itemShippingCharges = typeof item.DeliveryCharges === "number"
                    ? item.DeliveryCharges
                    : 0;
                  const itemSubTotal = parseFloat(item?.SubTotal) || 0;

                  discount += itemDiscount;
                  shippingCharges += itemShippingCharges;
                  totalAmountWithoutDiscount += itemSubTotal;

                  return (
                    <div className="single-product-info" key={index}>
                      <div className="checkout-products-information">
                        <div className="checkout-products-img-quantity">
                          <img src={item.ImageURL} alt="product-img" />
                          <span>{item.ItemQty}</span>
                        </div>
                        <div>
                          <p className="checkout-products-title">
                            {item.ItemName}
                          </p>
                          <p className="order-summary-item-description">
                            {item.ItemDescription}
                          </p>
                          <p style={{ fontSize: "0.8em" }}>
                            ${item.UnitPrice} x {item.ItemQty}
                          </p>
                          {itemDiscount !== 0 ? (
                            <>
                              <p style={{ color: 'var(--light-green-color)', fontSize: "0.9rem", marginTop: '-0.25rem' }}>
                                Save ${itemDiscount}
                              </p>
                              <div className="order-summary-item-discount">
                                <p style={{ textDecoration: 'line-through', color: 'var(--grey-text-color)' }}>
                                  ${itemSubTotal}
                                </p>
                                <p><b><i>Price :</i></b> ${item.NetItemAmount}</p>
                              </div>
                            </>
                          ) : (
                            <div className="order-summary-item-discount">
                              <i style={{ fontSize: '0.9rem' }}>Price :</i> <p style={{ fontWeight: 'bold' }}>${item.TotalAmount}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="order-summary-checkout-product-pricing">
                <div className="checkout-product-price-div">
                  <p>Total Discount</p>
                  <p>${discount}</p>
                </div>

                <div className="checkout-product-price-div">
                  <p>Delivery Charges</p>
                  <p>${shippingCharges}</p>
                </div>

                <div className="checkout-product-price-div">
                  <p>Sub total</p>
                  <p>${totalAmountWithoutDiscount}</p>
                </div>

                <hr />

                <div className="order-summary-checkout-product-price-div" style={{ marginTop: '-1.25rem' }}>
                  <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Total
                  </p>
                  <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    ${totalAmountWithoutDiscount + shippingCharges - discount}
                  </p>
                </div>

                {productData[0]?.PaidStatus === false &&
                  <div style={{ marginTop: '-1rem' }}>
                    <p style={{ color: 'var(--grey-text-color)' }} >
                      <b>Note: </b>
                      The payment link will expire in 15 days. If payment is not completed by then,
                      your order will be automatically canceled.
                    </p>
                    <Button
                      text='Pay Now'
                      style={{ padding: '0rem', lineHeight: 0, height: '3rem', width: '100%' }}
                      onClick={handlePayment}
                    />
                  </div>
                }


              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-order-summary-empty">
          <h1 className="order-summary-heading">No order found</h1>
          <Button text='Continue Shopping' onClick={() => navigate('/shop')} />
          <IoBanOutline size={50} className="order-not-found-img" />
        </div>
      )}
      <Footer />
    </>
  );
}
