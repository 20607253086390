import React from 'react';
import "./Card.css"
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import Button from "../../Button/Button"

// { img, price, title, id, ItemQty, CategoryName, SaleWithOutStock, Currency }

export default function Card({ img, price, title, id, SaleWithOutStock, ItemQty, DiscountWithPrice, Discount, ApplyDiscount, DiscountType }) {

    const navigate = useNavigate();

    const replaceSpacesWithHyphens = (str) => {
        return str.replace(/[\s']/g, '-').toLowerCase();
    };

    const finalPriceAfterDiscount = (discountPercent, price) => {
        const discountPrice = (discountPercent / 100) * price;
        const finalPrice = price - discountPrice
        return finalPrice
    }

    const truncateTitle = (title, length) => {
        if (title.length > length) {
            return title.slice(title, length) + '...';
        }
        if (title.length <= length) {
            return title;
        }
    }

    return (
        <div className='main-card'>
            <div onClick={() => navigate(`/${replaceSpacesWithHyphens(title)}/${id}`)} style={{ cursor: 'pointer' }} >
                <img className='card-img' src={img} alt={title} />
                <div className='card-title-stock'>
                    <p className='card-title' >{truncateTitle(title,20)}</p>
                    <div>
                        {(SaleWithOutStock === true) || (SaleWithOutStock === false && ItemQty > 0) ?
                            <p className='card-stock-instock'  > Instock </p> :
                            <p className='card-stock-outstock'  >
                                Out of Stock
                            </p>
                        }
                    </div>
                </div>

                <Rating readonly initialValue={4.5} size={17} className='rating-cart' />

                {ApplyDiscount ?
                    <div className='card-price-with-discount-div'>
                        {DiscountType === 0 &&
                            <>
                                <p className='card-price-with-discount'>${finalPriceAfterDiscount(Discount, price)}</p>
                                <div className='card-discount-price-div'>
                                    <p className="single-product-orignal-price">${price}</p>
                                    <p className='single-product-discount-percentage'> Flat {Discount}% off</p>
                                </div>
                            </>
                        }

                        {DiscountType === 1 &&
                            <>
                                <p className='card-price-with-discount'>${price - Discount}</p>
                                <div className='card-discount-price-div'>
                                    <p className="single-product-orignal-price">${price}</p>
                                    <p className='single-product-discount-percentage'> Flat ${Discount} off</p>
                                </div>
                            </>
                        }
                    </div> :

                    <div className='card-discount-only-price-div' >
                        <p className='card-price-with-discount'>${price}</p>
                    </div>
                }

            </div>
            <div className='view-btn'>
                <div>
                    <Button
                        text="View Details"
                        style={{ lineHeight: 0 }}
                        className='view-detail-cart-btn'
                        onClick={() => navigate(`/${replaceSpacesWithHyphens(title)}/${id}`)}
                    />
                </div>
            </div>
            {/* {popup ?
                <Popup
                    showPopup={popup}
                    message="Product added to cart!"
                    onClose={() => setPopup(false)}
                    img={img} title={title}
                    usedFor='cart' />
                : null}
            {popupWishList ?
                <Popup
                    showPopup={popupWishList}
                    message="Product added to Wishlist!"
                    onClose={() => setPopupWishList(false)}
                    img={img} title={title}
                    usedFor='wishlist'
                />

                : null} */}
        </div>
    );
}

