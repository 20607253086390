import React, { useState, useEffect } from 'react';
import "./CheckoutDeliveryForm.css"; // Fixed the file name typo
import { GET_METHOD } from "../../../api/api";
import { useSelector } from 'react-redux';

export default function CheckoutDeliveryForm({ onChange }) {
    const [formData, setFormData] = useState({
        country: '',
        firstName: '',
        lastName: '',
        address: '',
        phone: '',
        city: '',
        email: '',
        postalCode: '',
        state: '',
        description: '',
    });
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const errorInfo = useSelector((state) => state.store.errorDeliveryForm);

    useEffect(() => {
        const fetchCountries = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetCountry?CampusId=50`);
            setCountryData(res);
        };
        fetchCountries();
    }, []);

    useEffect(() => {
        if (formData.country) {
            const fetchStates = async () => {
                const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetState?CountryId=${formData.country}&CampusId=50`);
                setStateData(res);
            };
            fetchStates();
        }
    }, [formData.country]);

    useEffect(() => {
        if (formData.state) {
            const fetchCities = async () => {
                const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetCity?StateId=${formData.state}&CampusId=50`);
                setCityData(res);
            };
            fetchCities();
        }
    }, [formData.state]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log(`Changing ${name} to ${value}`);
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // console.log(formData,  'formData'); 
    };

    useEffect(() => {
        onChange(formData);
    }, [formData, onChange]);

    return (
        <div className='main-checkout-delivery-form'>
            <form>
                <h4 className='checkout-form-heading'>Delivery</h4>

                <input
                    type='text'
                    name='email'
                    placeholder='Enter email'
                    className={errorInfo?.email === '' ? 'checkoutform-big-input-required' : 'checkoutform-big-input'}
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    required
                    autoComplete='off'

                />

                <div className='checkoutform-double-input'>
                    <input
                        type='text'
                        name='firstName'
                        placeholder='First Name'
                        value={formData.firstName}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                        className={errorInfo?.firstName === '' ? 'checkout-normal-input-required' : 'checkout-normal-input'}
                        required
                        autoComplete='off'
                    />

                    <input
                        type='text'
                        name='lastName'
                        placeholder='Last Name'
                        value={formData.lastName}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                        className={errorInfo?.lastName === '' ? 'checkout-normal-input-required' : 'checkout-normal-input'}
                        required
                        autoComplete='off'
                    />
                </div>

                <select
                    name='country'
                    className={errorInfo?.country === '' ? 'checkoutform-big-select-required' : 'checkoutform-big-select'}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    value={formData.country}
                    required
                    autoComplete='off'
                >
                    <option value="" disabled>Select Country/Region</option>
                    {countryData?.map((country) => (
                        <option key={country?.CountryId} value={country?.CountryId}>{country?.CountryName}</option>
                    ))}
                </select>

                <select
                    name='state'
                    className='checkoutform-big-select'
                    value={formData.state}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    required
                    autoComplete='off'
                >
                    <option value="" disabled>Select State</option>
                    {stateData?.map((state) => (
                        <option key={state?.StateId} value={state?.StateId}>{state?.StateName}</option>
                    ))}
                </select>

                <div className='checkoutform-double-input'>
                    <select
                        name='city'
                        className='checkoutform-select'
                        value={formData.city}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                        required
                        autoComplete='off'
                    >
                        <option value="" disabled>Select City</option>
                        {cityData?.map((city) => (
                            <option key={city?.CityId} value={city?.CityId}>{city?.CityName}</option>
                        ))}
                    </select>

                    <input
                        type='text'
                        name='postalCode'
                        placeholder='Postal Code'
                        value={formData.postalCode}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                        className={errorInfo?.postalCode === '' ? 'checkout-normal-input-required' : 'checkout-normal-input'}
                        required
                        autoComplete='off'
                    />
                </div>

                <input
                    name='address'
                    className={errorInfo?.address === '' ? 'checkoutform-big-input-required' : 'checkoutform-big-input'}
                    placeholder='Complete Address'
                    type='text'
                    value={formData.address}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    required
                    autoComplete='off'
                />

                <input
                    name='phone'
                    placeholder='Phone'
                    className={errorInfo?.phone === '' ? 'checkoutform-big-input-required' : 'checkoutform-big-input'}
                    value={formData.phone}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    required
                    autoComplete='off'
                />

                <textarea
                    name='description'
                    placeholder='Description'
                    className='checkoutform-big-input'
                    style={{ height: '50px' }}
                    value={formData.description}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    autoComplete='off'
                />
            </form>
        </div>
    );
}
