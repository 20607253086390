import React, { useState, useRef } from 'react';
import './ImageZoom.css';
import { FaSearchPlus } from "react-icons/fa";
import { FaSearchMinus } from "react-icons/fa";

const ImageZoom = ({ src, alt, style }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const containerRef = useRef(null);
  const lastTouchPositionRef = useRef(null);

  const handleZoomToggle = () => {
    setIsZoomed((prevZoom) => !prevZoom);
    if (isZoomed) {
      setPosition({ x: 0, y: 0 });
    }
  };

  const handleMouseDown = (e) => {
    if (isZoomed) {
      setDragging(true);
      e.preventDefault();
    }
  };

  const handleMouseMove = (e) => {
    if (dragging && isZoomed) {
      moveImage(e.movementX, e.movementY);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleTouchStart = (e) => {
    if (isZoomed && e.touches.length === 1) {
      setDragging(true);
      lastTouchPositionRef.current = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      };
    }
  };

  const handleTouchMove = (e) => {
    if (dragging && isZoomed && e.touches.length === 1) {
      const touch = e.touches[0];
      const deltaX = touch.clientX - lastTouchPositionRef.current.x;
      const deltaY = touch.clientY - lastTouchPositionRef.current.y;

      moveImage(deltaX, deltaY);

      lastTouchPositionRef.current = {
        x: touch.clientX,
        y: touch.clientY,
      };
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  const moveImage = (deltaX, deltaY) => {
    const container = containerRef.current;
    const image = container.querySelector('img');

    // Calculate new positions
    const newX = position.x + deltaX;
    const newY = position.y + deltaY;

    // Get boundaries
    const maxX = (image.width * 2 - container.clientWidth) / 2;
    const maxY = (image.height * 2 - container.clientHeight) / 2;

    // Apply boundaries
    setPosition({
      x: Math.max(-maxX, Math.min(newX, maxX)),
      y: Math.max(-maxY, Math.min(newY, maxY)),
    });
  };

  const zoomStyles = isZoomed
    ? {
        transform: `scale(2) translate(${position.x}px, ${position.y}px)`,
        cursor: 'grab',
      }
    : {};

  return (
    <div
      className="image-container"
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <img
        src={src}
        alt={alt}
        className={`main-product-image ${isZoomed ? 'zoomed' : ''}`}
        style={{ ...style, ...zoomStyles }}
        onMouseDown={handleMouseDown}
      />
      <div className="magnifying-glass" onClick={handleZoomToggle} style={{ backgroundColor: 'transparent' }}>
        {isZoomed ? <FaSearchMinus size={25} color='var(--primary-text-color)' /> : <FaSearchPlus size={25} color='var(--primary-text-color)' />}
      </div>
    </div>
  );
};

export default ImageZoom;
