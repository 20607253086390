import React, { useState } from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';

const TabComponent = ({ description }) => {
    const [activeTab, setActiveTab] = useState('description');

    const navigate = useNavigate();

    // Data for the tabs
    const tabContent = {
        description: (
            <div className='tabs-section' >
                <h2>Description</h2>
                <p>
                    {description}
                </p>
            </div>
        ),
        delivery: (
            <div className='tabs-section'>
                <h2>Delivery Policy</h2>
                <p>
                    Orders are typically processed within 1-2 business days. Delivery times
                    depend on the location ,shipping method and quantity chosen during checkout.
                </p>
                <p>International Shipping: 10 - 15 business days.</p>
                <p>Delivery times may vary due to courier delays, customs processing for international
                    orders, and holidays. Leathers Crafts is not responsible for shipping carrier
                    delays or customs procedures.</p>
                <p>You'll receive a confirmation email with tracking details once your order ships.</p>

                <p onClick={() => navigate('/deliverypolicy')} className='tabcomponent-link' ><b>for detailed delivery policy</b></p>
            </div>
        ),
        refund: (
            <div className='tabs-section'>
                <h2>Refund Policy</h2>
                <p>
                    Refunds can be requested within 30 days of purchase.
                    Shipping costs are non-refundable except in the case of errors.
                    Refunds will be processed back to the original payment method
                    within 1-7 business days.
                </p>

                <p onClick={() => navigate('/refundpolicy')} className='tabcomponent-link' ><b>for detailed refund policy</b></p>
            </div>
        )
    };

    return (
        <div className="tab-container">
            {/* Tab Headers */}
            <div className="tab-header">
                <button
                    className={activeTab === 'description' ? 'active-tab' : ''}
                    onClick={() => setActiveTab('description')}
                >
                    Description
                </button>
                <button
                    className={activeTab === 'delivery' ? 'active-tab' : ''}
                    onClick={() => setActiveTab('delivery')}
                >
                    Delivery Policy
                </button>
                <button
                    className={activeTab === 'refund' ? 'active-tab' : ''}
                    onClick={() => setActiveTab('refund')}
                >
                    Refund Policy
                </button>
            </div>

            <div className="tab-content">
                {tabContent[activeTab]}
            </div>
        </div>
    );
};

export default TabComponent;
