import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from '../Pages/HomePage/HomePage'
import ShoppingPage from '../Pages/ShoppingPage/ShoppingPage'
import ProductPage from '../Pages/ProductPage/ProductPage';
import About from '../Pages/About/About';
import Contact from '../Pages/Contact/Contact';
import ScrollToTop from '../Component/ScrollToTop/ScrollToTop';
import SizePage from '../Pages/SizePage/SizePage';
import PrivacyPolicyPage from "../Pages/PrivacyPolicy/PrivacyPolicyPage"
import DeliveryPolicyPage from "../Pages/PrivacyPolicy/DeliveryPolicyPage"
import RefundPolicy from "../Pages/PrivacyPolicy/RefundPolicyPage";
import CheckoutPage from "../Pages/CheckoutPage/CheckoutPage"
import ResettingState from '../Component/ResettingState/ResettingState';
import OrderSummary from '../Pages/OrderSummary/OrderSummary';
import TermsConditionsPage from '../Pages/PrivacyPolicy/TermsConditionsPage';

export default function Routing() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/shop" element={<ShoppingPage />} />
                <Route path='/category/:title/:CategoryId' element={<ShoppingPage />} />
                <Route path='/:title/:id' element={<ProductPage />} />
                <Route path='/search/:keywords' element={<ShoppingPage />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
                <Route path="/sizechart" element={<SizePage />} />
                <Route path='/termsconditions' element = {<TermsConditionsPage />} /> 
                <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
                <Route path="/deliverypolicy" element={<DeliveryPolicyPage />} />
                <Route path='/refundpolicy' element={<RefundPolicy />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path='/ordersummary/:id' element={<OrderSummary />} />
                <Route path='/resetredux' element={<ResettingState />} />
            </Routes>
        </BrowserRouter>
    )
}
