import React, { useState } from 'react'
import Button from '../../Button/Button'
import "./styles.css"
import { useNavigate } from 'react-router-dom';
import PopupForm from '../PopupForm/PopupForm';

export default function SearchOrder() {

    const [order, setOrder] = useState('');
    const [popupSucess, setPopupSucess] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [sucess, setSucess] = useState(true);
    const navigate = useNavigate();

    const handleOrderNumber = (event) => {
        event.preventDefault();
    
        if (order === '') {
            setPopupSucess(true);
            setSucess(false);
            setFormMessage('Order Number is required');
            setTimeout(() => {
                setPopupSucess(false);
            }, 5000);
        } else {
            navigate(`/ordersummary/${order}`);
        }
    }
    return (
        <form onSubmit={handleOrderNumber}>
            <label className='newsletter-label'>Track your order status and stay updated with real-time delivery progress</label>
            <div className='search-order-main' >
                <input
                    className='search-order-input'
                    placeholder='Enter order number'
                    onChange={(e) => setOrder(e.target.value)}
                />
                <Button text='Search' style={{ lineHeight: 0, padding: 0, height: '35px', width: '100px' }} type='Submit' />
            </div>
            {popupSucess &&
                <PopupForm
                    showPopup={popupSucess}
                    message={formMessage}
                    onClose={() => setPopupSucess(false)}
                    title='Subscription form'
                    sucess={sucess}
                />        
            }
        </form>
    )
}
