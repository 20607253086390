import React, { useEffect, useState } from "react";
import AllProducts from "../../Component/AllProductsComponents/AllProducts/AllProducts";
import Navbar from "../../Component/Navigation/Navbar/Navbar";
import ProductFilter from "../../Component/AllProductsComponents/ProductFilter/ProductFilter";
import Footer from "../../Component/Footer/Footer";
import "./ShoppingPage.css";
import { GET_METHOD } from "../../api/api";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../Component/Loader/Loader";
import { useSelector } from "react-redux";
// import { useLocation } from 'react-router-dom';

export default function ShoppingPage() {
  const categories = useSelector((state) => state.store.category);
  const { CategoryId, keywords } = useParams();
  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [keywordData, setKeywordData] = useState([]);

  const [inStockCount, setInStockCount] = useState(0);
  const [outStockCount, setOutStockCount] = useState(0);

  const path = window.location.pathname;
  const afterShop = path.split("/shop/")[1];

  const [filters, setFilters] = useState({
    availability: "",
    priceRange: [0, 1000],
    selectedCategory: CategoryId ? CategoryId : 0,
  });

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      if (CategoryId) {
        setLoading(true);
        const url = `https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=${CategoryId}`;
        const res = await GET_METHOD(url);
        setCategoryData(res || []);
        setLoading(false);
      }
    };

    fetchCategoryProducts();
  }, [CategoryId]);

  useEffect(() => {
    const fetchKeywordProducts = async () => {
      if (keywords) {
        setLoading(true);
        const url =
          "https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=0";
        const res = await GET_METHOD(url);

        const filteredProducts = res?.filter((product) => {
          const itemName = product?.ItemName
            ? product.ItemName.toLowerCase()
            : "";
          const description = product?.Description
            ? String(product.Description).toLowerCase()
            : "";
          return (
            itemName.includes(keywords.toLowerCase()) ||
            description.includes(keywords.toLowerCase())
          );
        });

        setKeywordData(filteredProducts || []);
        setLoading(false);
      }
    };

    fetchKeywordProducts();
  }, [keywords]);

  useEffect(() => {
    const fetchAllProducts = async () => {
      if (!keywords && !CategoryId && !afterShop) {
        setLoading(true);
        const url =
          "https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=0";
        const res = await GET_METHOD(url);
        setApiData(res || []);
        setLoading(false);
      }
    };

    fetchAllProducts();
  }, [CategoryId, keywords, afterShop]);

  useEffect(() => {
    const fetchFilteredProducts = async () => {
      setLoading(true);
      if (filters.priceRange[0] !== 0 || filters.priceRange[1] !== 0) {
        const url = `https://knitting.azurewebsites.net/api/ECommerceApi/GetFilterWiseItem?CampusId=50&CategoryId=${
          filters.selectedCategory > 0 ? filters.selectedCategory : 0
        }&MinPrice=${filters.priceRange[0]}&MaxPrice=${filters.priceRange[1]}`;
        const res = await GET_METHOD(url);
        setCategoryData(res || []);
        setLoading(false);
      } else if (
        filters.priceRange[0] === 0 &&
        filters.priceRange[1] === 1000
      ) {
        const url = `https://knitting.azurewebsites.net/api/ECommerceApi/GetFilterWiseItem?CampusId=50&CategoryId=${
          filters.selectedCategory > 0 ? filters.selectedCategory : 0
        }&MinPrice=${filters.priceRange[0]}&MaxPrice=${filters.priceRange[1]}`;
        const res = await GET_METHOD(url);
        setCategoryData(res || []);
        setLoading(false);
      }
    };

    fetchFilteredProducts();
  }, [filters]);

  useEffect(() => {
    const handleFilterCount = async () => {
      const res = await GET_METHOD(
        `https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=${
          filters.selectedCategory > 0 ? filters.selectedCategory : 0
        }`
      );
      const instock = res?.filter(
        (item) =>
          item?.SaleWithOutStock === true ||
          (item?.SaleWithOutStock === false && item?.ItemQty > 0)
      );
      const outstock = res?.filter(
        (item) => item?.SaleWithOutStock === false && item.ItemQty === 0
      );
      setInStockCount(instock?.length);
      setOutStockCount(outstock?.length);
      if (afterShop === "instock") {
        setApiData(instock);
      } else if (afterShop === "outstock") {
        setApiData(outstock);
      }
    };

    handleFilterCount();
  }, [filters.selectedCategory, afterShop]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/shop") {
      const defaultPriceRange = [0, 1000];
      handleFilterChange({
        priceRange: defaultPriceRange,
        selectedCategory: 0,
      });
    }
  }, [location.pathname]);

  const displayedData = () => {
    if (keywords) {
      // console.log('displayed keywords')
      return keywordData;
    }

    if (filters.priceRange[0] !== 0 || filters.priceRange[1] !== 1000) {
      // console.log('displayed filterData');
      return categoryData;
    }

    if (CategoryId) {
      // console.log('displayed category data' , categoryData);
      return categoryData;
    } else {
      // console.log('displayed api Data', apiData);
      return apiData;
    }
  };

  // console.log(displayedData(), 'displayedData');

  return (
    <div style={{ width: "100%" }}>
      {loading && <Loader />}
      <Navbar />
      <div className="shopping-page-product-filter">
        <ProductFilter
          categories={categories}
          onFilterChange={handleFilterChange}
          inStockCount={inStockCount}
          outOfStockCount={outStockCount}
          categoryId={CategoryId}
          setFilters={setFilters}
        />
        <AllProducts apiData={displayedData()} />
      </div>
      <Footer />
    </div>
  );
}
