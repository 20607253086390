import React, { useState } from "react";
import "./Cart.css";
import { useDispatch, useSelector } from "react-redux";
import {
  cartRemoveAllProducts,
  cartRemoveProducts,
  updateProductQuantity,
} from "../../../stores/slice";
import { useNavigate } from "react-router-dom";
import Button from "../../Button/Button";
import { FaCartArrowDown } from "react-icons/fa";
// import SearchOrder from "../SearchOrder/SearchOrder";

export default function Cart({ isOpen, onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.store.cart);
  const [isClosing, setIsClosing] = useState(false);

  const additionCart = (index, quantity) => {
    dispatch(updateProductQuantity({ index: index, quantity: quantity + 1 }));
  };

  const subtractionCart = (index, quantity) => {
    if (quantity > 1) {
      dispatch(updateProductQuantity({ index: index, quantity: quantity - 1 }));
    }
  };

  const arrayTotal = (arr) => arr.reduce((total, num) => total + num, 0);

  const handleDelete = (index) => {
    dispatch(cartRemoveProducts({ ItemId: cart[index].ItemId, index }));
  };


  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 100);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setIsClosing(true);
      handleClose();
    }
  };

  // console.log(cart, 'cart');

  if (!isOpen && !isClosing) return null;

  return (
    <div
      className={`modal-overlay ${isClosing ? "modal-overlay-closing" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-content ${isClosing ? "main-cart-closing" : ""}`}>
        {cart?.length > 0 ? (
          <div className="main-cart">
            <button className="close-modal" onClick={handleClose}>
              ×
            </button>
            <h1 className="cart-heading">Your Cart</h1>
            <div className="sub-cart">
              {cart?.map((item, index) => {
                const totalProductPrice = item?.purchaseQuantity * item?.price;
                const totalProductPriceWthDiscount = item?.purchaseQuantity * item?.discountedPrice
                return (
                  <div className="cart-data" key={index}>
                    <div className="cart-img-div">
                      <img
                        src={item?.img}
                        className="cart-item-image"
                        alt="product-img"
                      />
                    </div>

                    <div className="cart-item-data">
                      <p
                        className="cart-item-title"
                        onClick={() =>
                          navigate(`/singleproduct/${item.ItemId}`)
                        }
                      >
                        {item?.title}
                      </p>
                      {item?.size ? (
                        <p>
                          {" "}
                          Size : <b>{item?.sizeValue}</b>{" "}
                        </p>
                      ) : null}
                      {item?.color ? (
                        <p>
                          {" "}
                          Color : <b> {item?.colorValue} </b>{" "}
                        </p>
                      ) : null}
                      {item?.applyDiscount && <p style={{ color: 'lightgreen', fontWeight: '500' }} >Save ${item?.discount * item?.purchaseQuantity}</p>}
                      {item?.applyDiscount ?
                        <div style={{ display: 'flex', justifyContent: 'flex-start ', alignItems: 'center', gap: '10px' }}>
                          <p >
                            {" "}
                            Price : <b style={{ textDecoration: 'line-through', color: 'var(--grey-text-color)' }} > ${totalProductPrice} </b>{" "}
                          </p>
                          <p>
                            {" "}
                            <b> ${totalProductPriceWthDiscount}</b>
                          </p>
                        </div> :
                        <p>
                          {" "}
                          Price : <b> ${totalProductPrice} </b>{" "}
                        </p>
                      }

                      <div className="cart-quantity-cart">
                        <div>
                          <p>
                            {" "}
                            <b>QTY :</b>{" "}
                          </p>
                        </div>

                        <div className="cart-operations-cart-slider">
                          <button
                            className="cart-operation-btn"
                            onClick={() =>
                              subtractionCart(index, item?.purchaseQuantity)
                            }
                          >
                            -
                          </button>
                          <input
                            value={item?.purchaseQuantity}
                            disabled
                            className="quantity-input-cart"
                          />
                          <button
                            className="cart-operation-btn"
                            onClick={() =>
                              additionCart(index, item?.purchaseQuantity)
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <p
                        className="remove-text"
                        onClick={() => handleDelete(index)}
                      >
                        Remove
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="total-price" style={{marginTop : '-0.5rem' }}  >
              <div className="total-price-shipping">
                <p style={{ textTransform: "uppercase" }}>
                  {" "}
                  <b>Shipping</b>{" "}
                </p>
                <p style={{fontSize : '1em'}}>$0</p>
              </div>

              <div className="total-price-shipping" style={{marginTop : '-1.15rem' }}  >
                <p style={{ textTransform: "uppercase" }}>
                  {" "}
                  <b>Discount</b>{" "}
                </p>
                <p style={{fontSize : '1em'}}>
                  $
                  {arrayTotal(
                    cart.map((item) => item.purchaseQuantity * item.discount)
                  )}
                </p>
              </div>

              {/* <div className="total-price-all-order">
                <p style={{ textTransform: "uppercase" }}>
                  <b>Total Price</b>
                </p>
                <p>
                  $
                  {arrayTotal(
                    cart.map((item) => item.purchaseQuantity * item.price)
                  )}
                </p>
              </div> */}

              <div className="total-price-all-order" style={{marginTop : '-1.15rem' }} >
                <p style={{ textTransform: "uppercase" }}>
                  <b>total price</b>
                </p>
                <p style={{fontSize : '1em'}}>
                  $
                  {arrayTotal(
                    cart.map((item) => item.purchaseQuantity * item.discountedPrice)
                  )}
                </p>
              </div>

              {/* <div className="checkout-price-all-order">
                <Button
                  text="Check out"
                  style={{
                    padding: "0rem",
                    lineHeight: "0",
                    height: "35px",
                    width: "150px",
                  }}
                  onClick={() => navigate("/checkout")}
                />

                <p>Taxes are included</p>
              </div> */}
              <div style={{ padding:'0 0.5rem', marginTop : '-0.5rem'}}>
                <Button
                  text="Check out"
                  style={{
                    padding: "0rem",
                    lineHeight: "0",
                    height: "40px",
                    width: "100%",
                  }}
                  onClick={() => navigate("/checkout")}
                />
              </div>


              <div className="privacy-policy-clear-cart-div" style={{ marginTop: '0rem' }} >
                <button
                  className="clear-cart-btn"
                  style={{ padding: "0rem", lineHeight: "0" }}
                  onClick={() => dispatch(cartRemoveAllProducts())}
                >
                  Clear Cart
                </button>

                <button
                  className="clear-cart-btn"
                  style={{ padding: "0rem", lineHeight: "0" }}
                  onClick={() => navigate('/privacypolicy')}
                >
                  Privacy & Policy
                </button>

              </div>

            </div>
            {/* <SearchOrder /> */}
          </div>
        ) : (
          <div className="main-cart-emtpy">
            <button className="close-modal" onClick={handleClose}>
              ×
            </button>
            <h1>Your cart is empty</h1>
            <FaCartArrowDown
              style={{
                textAlign: "center",
                fontSize: "3em",
                color: "var(--grey-text-color)",
                marginBottom: "2rem",
              }}
            />
            <Button
              text="Continue Shopping"
              onClick={() => {
                handleClose();
                navigate("/shop");
              }}
            />
            {/* <SearchOrder /> */}
          </div>
        )}
      </div>
    </div>
  );
}
