import React from 'react'
import Navbar from '../../Component/Navigation/Navbar/Navbar'
// import Banner from '../../Component/Banner/Banner'
import ContactDiv from '../../Component/ContactComponents/ContactDiv/ContactDiv'
import Footer from '../../Component/Footer/Footer'
import ContactForm from '../../Component/ContactComponents/ContactForm/ContactForm'
import ContactUsForm from '../../Component/ContactComponents/ContactUsForm/ContactUsForm'

export default function Contact() {
    return (
        <div style={{ width: '100%' }}>
            <Navbar />
            {/* <Banner text='Contact' /> */}
            <div  >
                <ContactDiv />
                <ContactUsForm />
                <ContactForm />
                <Footer />
            </div>
        </div>
    )
}
