import React from 'react';
import "./OurBestSellerCard.css";
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';


export default function OurBestSellerCard({ Id, price, title, img, SaleWithOutStock, ItemQty, Discount, DiscountWithPrice, ApplyDiscount, DiscountType }) {
    const navigate = useNavigate();

    const replaceSpacesWithHyphens = (str) => {
        return str.replace(/[\s']/g, '-').toLowerCase();
    };

    const handleNavigation = (Id, title) => {
        const productTitle = replaceSpacesWithHyphens(title);
        navigate(`/${productTitle}/${Id}`);
        window.scrollTo(0, 0);
    }

    const finalPriceAfterDiscount = (discountPercent, price) => {
        const discountPrice = (discountPercent / 100) * price;
        const finalPrice = price - discountPrice
        return finalPrice
    }

    const truncateTitle = (title, length) => {
        if (title.length > length) {
            return title.slice(title, length) + '...';
        }

        if (title.length <= length) {
            return title;
        }
    }

    return (
        <div className='main-our-best-seller-card' onClick={() => handleNavigation(Id, title)}>
            <div>
                <div className='our-seller-img-div'>
                    <img src={img} alt='' />
                </div>
                <div className='our-best-seller-title-stock'>
                    <p className='our-best-seller-title'>{truncateTitle(title,20)}</p>
                    <div>
                        {(SaleWithOutStock === true) || (SaleWithOutStock === false && ItemQty > 0) ?
                            <p className='card-stock-instock' > Instock </p> :
                            <p className='card-stock-outstock'  >
                                Out of Stock!
                            </p>
                        }
                    </div>
                </div>

                <div className='our-best-seller-rating-stars'>
                    <Rating size={17} readonly initialValue={4} />
                </div>
                {ApplyDiscount ?
                    <div className='our-best-seller-card-price-with-discount-div'>
                        {DiscountType === 0 &&
                            <>
                                <p className='card-price-with-discount'>${finalPriceAfterDiscount(Discount, price)}</p>
                                <div className='card-discount-price-div'>
                                    <p className="single-product-orignal-price">${price}</p>
                                    <p className='single-product-discount-percentage'> Flat {Discount}% off</p>
                                </div>
                            </>
                        }

                        {DiscountType === 1 &&
                            <>
                                <p className='card-price-with-discount'>${price - Discount}</p>
                                <div className='card-discount-price-div'>
                                    <p className="single-product-orignal-price">${price}</p>
                                    <p className='single-product-discount-percentage'> Flat ${Discount} off</p>
                                </div>
                            </>
                        }
                    </div>
                    :
                    <div className='card-without-discount-price-div'>
                        <p className='card-price-with-discount'>${price}</p>
                    </div>
                }

            </div>
        </div>
    )
}
