import React from 'react'
import Navbar from '../../Component/Navigation/Navbar/Navbar'
import Footer from '../../Component/Footer/Footer'
import DeliveryPolicy from '../../Component/AboutComponents/DeliveryPolicy/DeliveryPolicy'

export default function DeliveryPolicyPage() {
  return (
    <div>
      <Navbar />
      <div style={{paddingTop : '7rem'}}>
       <DeliveryPolicy /> 
        <Footer /> 
      </div>
    </div>
  )
}
