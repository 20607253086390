import React from 'react';
import "./Button.css"

export default function Button ({text , style , onClick, className, disable, type} )  {
    return (
        <div className='btn-main'>
        <button className= {className} onClick={onClick} style={style} disabled = {disable} type={type} >{text}</button>
        </div>
    );
}

