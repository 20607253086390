import React, { useState, useEffect } from 'react';
import './CheckoutForm.css';
import CheckoutDeliveryForm from '../CheckoutDeliveryForm/CheckoutDeliveryForm';
import { setDeliveryInformation } from '../../../stores/slice';
import { useDispatch } from 'react-redux';

export default function CheckoutForm() {
    const [selectedPayment, setSelectedPayment] = useState('2checkout');
    const [deliveryFormData, setDeliveryFormData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        const formData = {
            deliveryMethod: selectedPayment,
            ...deliveryFormData,
        };

        dispatch(setDeliveryInformation(formData)); 
        // console.log('Form Data Updated:', formData); 
    }, [selectedPayment, deliveryFormData, dispatch]); 

    const handlePayment = (event) => {
        setSelectedPayment(event.target.value);
    };

    return (
        <div className='main-checkoutform'>
            <form className='checkout-form'>
                <h4 className='checkout-form-heading'>Payment Method</h4>
                <p style={{ color: 'var(--primary-text-color)' }}>
                    All transactions are secured and encrypted
                </p>
                <div className='checkout-method-div'>
                    <label className={selectedPayment === "2checkout" ? 'checkout-method-div-label-selected' : 'checkout-method-div-label'}>
                        <input
                            type="radio"
                            name="paymentMethod"
                            value="2checkout"
                            checked={selectedPayment === "2checkout"}
                            onChange={handlePayment}
                        /> &nbsp;
                        2 checkout (Pay via Debit/Credit/Wallet/Bank Account)
                    </label>
                </div>

                
                <CheckoutDeliveryForm onChange={setDeliveryFormData} />
            </form>
        </div>
    );
}
