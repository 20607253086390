import React from 'react'

export default function TermsConditions() {
    return (
        <div className='container'>
            <header className="header">
                <h1>Terms & Conditions</h1>
            </header>

            <section className='section' >
                <h2>Payments and Pricing</h2>
                <ul>
                    <li>Payments can be made via PayPal, VISA, MasterCard, American Express,
                        Discover, Apple Pay, Amazon Pay, and AfterPay</li>

                    <li>Your payment card details are safeguarded using SSL, an industry-leading
                        encryption standard. When you make a payment for our products or services
                        on our website, the information you provide is transmitted directly to
                        our payment provider via a secure connection.</li>

                    <li>Payments are processed in USD. If your card's currency differs,
                        you can still proceed with your order. Our system will accept the
                        order by deducting the equivalent amount of the respective currency.</li>
                </ul>

                <h2>Cancellation Policy</h2>
                <ul>
                <li>Cancellations are accepted within 2 days of order placement. Requests can be submitted via email at leathercraft785@gmail.com .
                </li>

                <li>Once an order has been dispatched or is under customs clearance, cancellation
                    is no longer possible. In this scenario, accepting the parcel and arranging
                    its return will be the only option.</li>

                <li>If our Customer Support Team has promised delivery on your conveyed timeline,
                    the order qualifies as a time-sensitive order. As a result of the necessary
                    measures we take to ensure timely order completion, we are unable to accommodate
                    cancellations for time-sensitive orders once they have been placed.</li>

                    </ul>

                <h2>Order Acceptance & Payment</h2>
                <ul>
                    <li>Our website prices and product availability may change without prior notice.
                    </li>

                    <li>Errors will be corrected when discovered.</li>

                    <li>With a vast product range, occasional pricing discrepancies may arise 
                        despite our best efforts. We routinely verify prices during order 
                        processing and if a product's correct price is lower, we'll refund 
                        the difference. If the correct price exceeds the stated amount, 
                        we may contact you for instructions or reject the order, notifying you. 
                        Please note, we're not obligated to fulfill orders at incorrect (lower) 
                        prices.</li>

                    <li>We reserve the right to decline service to any individual, at any time, and for any reason.
                    </li>
                </ul>
            </section>
        </div>
    )
}
