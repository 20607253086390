import React, { useEffect } from "react";
import "./CheckoutProducts.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Button/Button";
import { setCheckOutInformation, setDeliveryInformation } from "../../../stores/slice";
import { GET_METHOD, POST_METHOD } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../../Loader/Loader";
import PopupForm from "../../CartComponents/PopupForm/PopupForm";
import { setErrorDeliveryForm } from "../../../stores/slice";


export default function CheckoutProducts() {
  const cart = useSelector((state) => state.store.cart);

  const checkoutInformation = useSelector(
    (state) => state.store.checkoutInformation
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const arrayTotal = (arr) => arr.reduce((total, num) => total + num, 0);

  const subTotal = arrayTotal(
    cart.map((item) => item.purchaseQuantity * item.price)
  );

  const discountTotal = arrayTotal(
    cart.map((item) => item.purchaseQuantity * item.discount)
  )

  const deliveryInfo = useSelector(
    (state) => state.store.deliveryInformation
  );

  const [loading, setLoading] = useState(false);

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    dispatch(setCheckOutInformation({ cart, deliveryInfo }));
  }, [cart, dispatch, deliveryInfo]);

  // console.log(deliveryInfo , 'deliveryInfo checkout products');

  const HandleButton = async () => {
    const country = checkoutInformation.deliveryInfo?.country;
    const state = checkoutInformation.deliveryInfo?.state;
    const firstName = checkoutInformation.deliveryInfo?.firstName;
    const lastName = checkoutInformation.deliveryInfo?.lastName;
    const postalCode = checkoutInformation.deliveryInfo?.postalCode;
    const phone = checkoutInformation.deliveryInfo?.phone;
    const city = checkoutInformation.deliveryInfo?.city;
    const address = checkoutInformation.deliveryInfo?.address;
    const email = checkoutInformation.deliveryInfo?.email;
    const description = checkoutInformation.deliveryInfo?.description;
    const deliveryMethod = checkoutInformation.deliveryInfo?.deliveryMethod;

    const errors = {};

    (country === '' || country === undefined) ? errors.country = '' : errors.country = country;
    (firstName === '' || firstName === undefined) ? errors.firstName = '' : errors.firstName = firstName;
    (lastName === '' || lastName === undefined) ? errors.lastName = '' : errors.lastName = lastName;
    (postalCode === '' || postalCode === undefined) ? errors.postalCode = '' : errors.postalCode = postalCode;
    (phone === '' || phone === undefined) ? errors.phone = '' : errors.phone = phone;
    (address === '' || address === undefined) ? errors.address = '' : errors.address = address;
    (email === '' || email === undefined) ? errors.email = '' : errors.email = email;

    if (Object.keys(errors).length > 0) {
      dispatch(setErrorDeliveryForm(errors));
      // console.log(errors, 'errors');
    }

    if (errors.country === '' || errors.email === '' || errors.firstName === '' || errors.lastName === '' || errors.phone === '' || errors.postalCode === '' || errors.address === '' || errors.length > 0) {
      return;
    }

    else {
      const ItemArray = checkoutInformation.cart?.map((cart) => {
        let obj = {
          ItemID: cart?.ItemId,
          Qty: cart?.purchaseQuantity,
          Discount: cart?.discount,
          Description: cart.size ? `${cart.optionNameSize} : ${cart.sizeValue} ` : "" + cart.color ? `${cart.optionNameColor} : ${cart.colorValue}` : "",
        };
        return obj;
      });

      const OptionsArray = checkoutInformation.cart?.map((cart) => {
        let obj = {
          ItemID: 0,
          SpecificationID: 0,
        };
        return obj;
      });

      // console.log("ItemArray", JSON.stringify(ItemArray));
      // console.log("OptionsArray", JSON.stringify(OptionsArray));

      setLoading(true);
      const res = await POST_METHOD(
        `https://knitting.azurewebsites.net/api/ECommerceApi/CreateCart?SessionID=0&CustomerID=100&LanguageID=100&CartDescription='All Specification Detail'&ItemArray=${JSON.stringify(
          ItemArray
        )}&OptionsArray=${JSON.stringify(
          OptionsArray
        )}&SelectStatus=Website&totalDiscount=0&CampusId=50&CountryId=${country}&StateId=${state}&CityId=${city}&FirstName=${firstName}&LastName=${lastName}&Address=${address}&PostalCode=${postalCode}&PhoneNo=${phone}&Email=${email}&Description=${description}&PaymentMethod=${deliveryMethod}`,
        {}
      );
      console.log('res', res);
      setLoading(false);
      const cartId = decodeURIComponent(res.data[0]?.CartId);
      console.log(cartId, 'decode cart id')

      // let paymentLink = '';

      if (res.success) {
        navigate(`/ordersummary/${cartId}`, { state: { checkoutData: res.data } });
        setLoading(false);
        dispatch(setDeliveryInformation([]));
        dispatch(setCheckOutInformation([]));
        dispatch(setErrorDeliveryForm([]));
      } else {
        console.log("checkout failed");
      }

      if (cartId) {
        const newWindow = window.open('', '_blank');
        const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/Checkout?CartId=${cartId}&CampusId=50`);
        console.log(res, 'res of cart-id api');
        if (res) {
          newWindow.location.href = res;
        } else {
          newWindow.close();
        }
      }
    }
  };

  useEffect(() => {
    const handleReload = () => {
      dispatch(setErrorDeliveryForm([]));
    };

    window.addEventListener('beforeunload', handleReload);
    return () => {
      window.removeEventListener('beforeunload', handleReload);
    };
  }, [dispatch]);

  // console.log('cart checkoutproducts', cart);

  return (
    <div className="main-checkout-products">
      {loading && <Loader />}
      <div className="sub-checkout-products">
        {cart?.length === 0 && (
          <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
            No products found in your cart
          </h1>
        )}
        {cart?.map((item, index) => {
          const totalProductPrice = item?.purchaseQuantity * item?.price;
          const totalProductPriceWthDiscount = item?.purchaseQuantity * item?.discountedPrice
          return (
            <div className="single-product-info" key={index}>
              <div className="checkout-products-information">
                <div className="checkout-products-img-quantity">
                  <img src={item?.img} alt="product-img" />
                  <span>{item?.purchaseQuantity}</span>
                </div>
                <div>
                  <p className="checkout-products-title">{item?.title}</p>
                  <p style={{ fontSize: "0.9em" }}>
                    ${item?.price} x {item?.purchaseQuantity}
                  </p>
                  {item?.applyDiscount && <p style={{ color: 'lightgreen', fontWeight: '500' }} >Save ${item?.discount * item?.purchaseQuantity}</p>}
                  {item?.applyDiscount ?
                    <div className="order-summary-item-discount" >
                      <p style={{ textDecoration: 'line-through', color: 'var(--grey-text-color)' }} >${totalProductPrice}</p>
                      <i>Price: </i> <p style={{ fontWeight: 'bold' }}>  ${totalProductPriceWthDiscount}</p>
                    </div>
                    :
                    <div className="order-summary-item-discount" >
                      <i>Price: </i>  <p style={{ fontWeight: 'bold' }} >${totalProductPrice}</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="checkout-product-pricing">
        <div className="checkout-product-price-div">
          <p>Sub total</p>
          <p>${subTotal}</p>
        </div>

        <div className="checkout-product-price-div">
          <p>Total Discount</p>
          <p>${discountTotal}</p>
        </div>

        <div className="checkout-product-price-div">
          <p>Shipping</p>
          <p>Free</p>
        </div>

        <div className="checkout-product-price-div" style={{ borderTop: '1px solid var(--primary-text-color)', marginTop: '0.15rem' }} >
          <h4 style={{ marginTop: '0.5rem' }} >Total</h4>
          <h4 style={{ marginTop: '0.5rem' }}  >${subTotal - discountTotal}</h4>
        </div>

        {/* className="place-order-privacy-policy"  */}
        <div >
          <Button text="Place order" onClick={HandleButton} style={{ width: '100%', lineHeight: 0, padding: '0rem', height: '40px' }} />
          <button
            className="privay-policy-btn"
            style={{ padding: "0rem", lineHeight: "0" }}
            onClick={() => navigate('/privacypolicy')}
          >
            <p>Privacy & Policy</p>
          </button>
        </div>

      </div>

      {popup && (
        <PopupForm
          title="Checkout failed"
          message="All fields are required in delievery information"
          onClose={() => setPopup(false)}
          showPopup={popup}
        />
      )}
    </div>
  );
}
