import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux'
import { store } from './stores/appStore';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';
import Loader from './Component/Loader/Loader';

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store);

root.render(
  <Provider store={store}>
    {/* <PersistGate persistor={persistor} loading={<Loader />}> */}
      <App />
    {/* </PersistGate> */}
  </Provider>
);

